* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat-ExtraBoldItalic"),
    url("fonts/Montserrat-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Space Mono";
  src: local("SpaceMono-Regular"),
    url("fonts/SpaceMono-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Space Mono Italic";
  src: local("SpaceMono-Italic"),
    url("fonts/SpaceMono-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Space Mono BoldItalic";
  src: local("SpaceMono-BoldItalic"),
    url("fonts/SpaceMono-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Space Mono Bold";
  src: local("SpaceMono-Bold"),
    url("fonts/SpaceMono-Bold.ttf") format("truetype");
}

$heading: "Montserrat", sans-serif;
$body: "Space Mono" monospace;
$mono: monospace;

body {
  width: 100%;
  height: 100vh;
  position: relative;
  margin: 0;
  letter-spacing: 0.09em;
  font-weight: 100;
}
h1 {
  margin-bottom: 1em !important;
}
p {
  font-size: 14px;
}
a {
  font-size: 14px;
}

.dFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  position: relative;
  flex-direction: column;
}

.logo {
  width: 80px;
  height: 80px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: none;
  margin: 40px auto 0px auto;
  img {
    width: 100%;
    height: 100%;
  }
}

.video-container {
  position: relative;
  height: 100%;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background: url("./img/poster.png") no-repeat;
  background-size: cover;
  transition: 1s opacity;
}

.video-thumb {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transition: opacity 400ms ease 0ms;
}
.tiny {
  filter: blur(20px);
  transform: scale(1.1);
  transition: visibility 0ms ease 400ms;
}

*:focus {
  outline: 0 !important;
  outline-offset: 0 !important;
  box-shadow: none !important;
}

.pinned {
  position: fixed;
  /*   border: 2px solid; */
  padding: 5px;
}
.pinned:nth-child(1) {
  /*   border-color: green; */
  top: 0;
  margin: 50px 0px 0px 50px;
  align-self: start;
}
.pinned:nth-child(2) {
  /*   border-color: red; */
  top: 0;
  right: 0;
  margin: 50px 50px 0px 0px;
}
.pinned:nth-child(3) {
  /*   border-color: yellow; */
  bottom: 0;
  left: 0;
  margin: 0px 50px 0px 50px;
}
.pinned:nth-child(4) {
  /*   border-color: pink; */
  bottom: 0;
  right: 0;
  margin: 0px 50px 50px 0px;
}

.pinned .chakra-button.active .chakra-button__icon {
  transform-origin: center center;
  animation: rotateIn 0.25s;
  transform: rotate(-45deg);
  position: relative;
}

.pinned .chakra-button .chakra-button__icon {
  font-family: $mono !important;
  transform-origin: center center;
  animation: rotateOut 0.25s;
  transform: rotate(0deg);
  position: relative;
}

@keyframes rotateIn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}
@keyframes rotateOut {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.contact {
  position: relative;
  width: 100%;
  top: 0;
  right: 0px;
  z-index: 800;
  font-family: $body;
  /*mix-blend-mode: difference;*/
  span {
    width: 40px;
    height: 2px;
    background-color: #fff;
    //transition: all 0.3s ease-in-out;
    + span {
      margin-top: 10px;
    }
    &:nth-child(2) {
      width: 30px;
      margin-left: 10px;
    }
  }
  &.active {
    span {
      &:nth-child(1) {
        transform-origin: center right;
        transform: rotate(-45deg) translateY(-4px);
      }
      &:nth-child(2) {
        opacity: 0;
        transform: translateX(30px);
      }
      &:nth-child(3) {
        transform-origin: center right;
        transform: rotate(45deg) translateY(4px);
      }
    }
  }
}

.nav_wrapper {
  position: fixed;
  width: 100%;
  top: 0;
  right: 0px;
  z-index: 800;
  font-family: $body;
  /* mix-blend-mode: difference; */
  .menu {
    @extend .dFlex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    width: 150px;
    height: 60px;
    color: #fff;
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
    padding-top: 30px;
    @media (max-width: 760px) {
      width: 80px;
    }
    span {
      width: 40px;
      height: 2px;
      background-color: #fff;
      //transition: all 0.3s ease-in-out;
      + span {
        margin-top: 10px;
      }
      &:nth-child(2) {
        width: 30px;
        margin-left: 10px;
      }
    }
    &:hover {
      span:nth-child(2) {
        margin-left: -10px;
      }
    }
    &.active {
      span {
        &:nth-child(1) {
          transform-origin: center right;
          transform: rotate(-45deg) translateY(-4px);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translateX(30px);
        }
        &:nth-child(3) {
          transform-origin: center right;
          transform: rotate(45deg) translateY(4px);
        }
      }
    }
  }
}

.header_wrapper {
  width: 150px;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 0;
  .scroll_down {
    position: absolute;
    bottom: 80px;
    width: 100%;
    text-align: center;
    font-family: $body;
    text-transform: uppercase;
    font-size: 14px;
    transform: rotate(90deg);
    cursor: pointer;
  }
  @media (max-width: 760px) {
    display: none;
  }
}

.nav_overlay {
  width: 100%;
  height: 100vh;
  margin: auto;
  position: fixed;
  top: 0;
  z-index: 500;
  visibility: hidden;
  overflow: hidden;
  span.bars {
    width: 100%;
    height: 100vh;
    position: relative;
    display: block;
    transform: translateX(100%);
    //transition: all 0.25s cubic-bezier(0.77, 0, 0.175, 1);
    /*     &:nth-child(1) {
      transition-delay: 0s;
    } */
  }
  &.active {
    visibility: visible;
    span.bars {
      transform: translateX(0);
    }
  }
}

.nav_items {
  @extend .dFlex;
  .nav_container {
    width: 100%;
    height: auto;
    overflow: scroll;
    .nav_link {
      overflow: hidden;
      a {
        display: block;
        text-align: center;
        text-decoration: none;
        font-size: 40px;
        font-family: $heading;
        font-weight: bold;
        position: relative;
        line-height: 1.5;
        text-transform: lowercase;
        padding-bottom: 30px;
        color: rgba(#ffffff, 0.5);
        transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
        transform: translateY(100%);
      }
    }
    &.active {
      .nav_link {
        a {
          transform: translateY(0);
          &.active {
            color: #fff;
            &:after {
              content: attr(data-num);
              position: absolute;
              color: #fff;
              top: 0;
              left: 0;
              right: 0;
              opacity: 0.2;
              letter-spacing: 0;
              transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
              transform: scale(2);
            }
          }
          &:hover {
            color: #fff;
            &:after {
              content: attr(data-num);
              position: absolute;
              color: #fff;
              top: 0;
              left: 0;
              right: 0;
              opacity: 0.2;
              letter-spacing: 0;
              transition: transform 1s cubic-bezier(0.4, 0, 0.2, 1);
              transform: scale(2);
            }
          }
        } //a
        &:nth-child(1) {
          a {
            transition-delay: 0.6s !important;
          }
        }
        &:nth-child(2) {
          a {
            transition-delay: 0.7s !important;
          }
        }
        &:nth-child(3) {
          a {
            transition-delay: 0.8s !important;
          }
        }
        &:nth-child(4) {
          a {
            transition-delay: 0.9s !important;
          }
        }
        &:nth-child(5) {
          a {
            transition-delay: 1s !important;
          }
        }
      }
    }
  }
}

.nav_overlay.contact-overlay.active {
  span.bars {
    background: #3820ff;
  }
}
.nav_overlay.connect-overlay.active {
  span.bars {
    background: #ff3c15;
  }
}
.nav_overlay.about-overlay.active {
  span.bars {
    background: #000000;
  }
}

.nav_overlay.box-active-exit {
  background: #fff !important;
}

.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 90%;
  left: 100%;
  margin-left: -60px;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.social {
  top: 0px;
  color: #fff;
  height: 250px;
  position: absolute;
  left: 30px;
  a {
    display: block;
    padding: 20px;
    position: relative;
    left: -100px;
    color: #fff;
    transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  } //a
  &.active {
    a {
      left: 0;
      &:nth-child(1) {
        transition-delay: 0.1s;
      }
      &:nth-child(2) {
        transition-delay: 0.2s;
      }
      &:nth-child(3) {
        transition-delay: 0.3s;
      }
      &:nth-child(4) {
        transition-delay: 0.4s;
      }
      &:nth-child(5) {
        transition-delay: 0.5s;
      }
    } //a
  } //active
} //nav_social

.copyright-wrapper {
  text-align: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 66px;
  overflow: hidden;
  z-index: -100;
  .copyright {
    font-size: 12px;
    text-transform: uppercase;
    transition: transform 1s cubic-bezier(0.77, 0, 0.175, 1) !important;
    transform: translateY(100%);
    letter-spacing: 1.2px;
    width: 72%;
    margin: 0 auto;
  }
  &.active {
    .copyright {
      transform: translateY(0);
    }
  }
} //copyright-wrapper

.nav_container .chakra-container {
  padding: 0;
  text-align: center;
}

.chakra-container.flex-container {
  @extend .dFlex;
  margin: 0 auto;
  padding: 0;
}

.box-enter .nav_container {
  opacity: 1;
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}
.box-exit .nav_container {
  opacity: 0;
  animation: fadeOut 0.5s;
  -webkit-animation: fadeOut 0.5s;
  -moz-animation: fadeOut 0.5s;
  -o-animation: fadeOut 0.5s;
  -ms-animation: fadeOut 0.5s;
}

.fade-out {
  animation: fadeOut 0.5s;
  -webkit-animation: fadeOut 0.5s;
  -moz-animation: fadeOut 0.5s;
  -o-animation: fadeOut 0.5s;
  -ms-animation: fadeOut 0.5s;
}

.fade-in {
  opacity: 1;
  animation: fadeIn 0.5s;
  -webkit-animation: fadeIn 0.5s;
  -moz-animation: fadeIn 0.5s;
  -o-animation: fadeIn 0.5s;
  -ms-animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.connect .chakra-form__error-message {
  color: #000000 !important;
}

.connect input[aria-invalid="true"],
.connect input[data-invalid] {
  border-color: #000000 !important;
}

.connect input:focus {
  border-color: #ffffff;
  box-shadow: 0 0 0 1px #ffffff;
}

form .message.success {
  color: #36ff1f;
}
form .message {
  margin-top: 1.5em;
  cursor: crosshair;
  display: inline-flex;
  white-space: nowrap;
  height: auto;
  align-items: center;
  vertical-align: baseline;
  -webkit-box-pack: center;
  padding: 0;
  justify-content: center;
  appearance: none;
  line-height: 1.25em;
}

input.chakra-input,
textarea.chakra-textarea {
  padding: 0.4em 0.45em 0.4em 0.45em !important;
  letter-spacing: 0.06em;
}

form .chakra-input__right-element {
  padding: 0 0.4em 0 0;
  width: auto;
}

form .chakra-input__right-element .message,
form .chakra-input__right-element .chakra-button {
  margin-top: 0.2em;
}
.css-1lymihm[data-loading],
.chakra-button[data-loading] {
  opacity: 1 !important;
}

.chakra-button {
  color: #ffffff;
}
.chakra-button[data-active] {
  color: #ff3c15;
}
textarea.chakra-textarea[aria-invalid="true"],
textarea.chakra-textarea[data-invalid] {
  border-color: #ff3c15;
  box-shadow: 0 0 0 1px #ff3c15;
  color: #ff3c15 !important;
}
.chakra-form-control div.chakra-form__error-message,
div.css-gfghaq.chakra-form__error-message {
  color: #ff3c15 !important;
}
.chakra-button[disabled]:hover {
  color: #ffffff !important;
}
button:hover,
button[data-hover] {
  color: #ff3c15;
}
button.chakra-button {
  color: #ffffff;
}
@media (hover: hover) {
  button:hover,
  button[data-hover] {
    color: #ffffff;
  }
}

.chakra-checkbox p {
  position: relative;
  top: 0.09em;
}

.moon {
  width: 33px;
  height: 33px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  background-color: #fff;
  transform: translateZ(0);

  &::before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    animation: flip 1s 0.5s steps(2) infinite alternate;
  }
}

.contact-overlay.active .moon {
  &::before {
    background: #3820ff !important;
  }
  .disc::before {
    background: #3820ff !important;
  }
}
.connect-overlay.active .moon {
  &::before {
    background: #ff3c15 !important;
  }
  .disc::before {
    background: #ff3c15 !important;
  }
}

.contact-overlay .chakra-checkbox__control polyline {
  color: #3820ff !important;
}
.connect-overlay .chakra-checkbox__control polyline {
  color: #ff3c15 !important;
}

form .chakra-input__right-element .moon {
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  top: -1px;
}

.vertical-line {
  width: 80%;
  position: relative;
  margin: 0 0 0px 0;
  padding: 0;
  border-top: 1px solid;
}
.vertical-line-center {
  position: relative;
  width: 10%;
  margin: 0;
  top: 1px;
  text-align: center;
}
.chakra-stack.css-84zodg {
  margin: 1em auto;
}

.disc {
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  animation: rotate 2s linear infinite;

  &::before,
  &::after {
    content: " ";
    display: block;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: transform 4s;
    position: absolute;
    backface-visibility: hidden;
  }
  &::before {
    background-color: #3820ff;
  }
  &::after {
    background-color: #fff;
    transform: rotateY(180deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

@keyframes flip {
  0% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

/*
.flex-child {
  flex: 1;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.flex-child:first-child {
  border-right: 1px solid #fff;
  margin: 0px 1em 0px 0px;
  padding: 1em 1em 1em 0em;
}

.flex-child {
  &:nth-child(2) {
    margin: 0 0 0 0;
    padding: 0;
    .chakra-button {
      margin: 0 0 0 0;
      padding: 0;
    }
  }
}

.flex-child form {
  &:nth-child(1) {
    .chakra-input.first {
      margin-top: 0;
    }
  }
} */

.chakra-container.flex-container {
  display: block;
}
.flex-child {
  flex: 1;
  margin: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.flex-child:first-child {
  border-right: none;
  /*   margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px; */
}

.chakra-form-control .first {
  margin-top: 0;
}

.flex-child.css-1dsj2ig {
  margin: 0em 0px 0px 0px;
  border-top: none;
  padding-top: 0;
  .chakra-button {
    padding: 0;
  }
}

.flex-child.chakra-stack.css-17ldpf5 {
  margin: 1.5em 0px 0px 0px;
  border-top: 1px solid;
  padding-top: 1em;
  .chakra-button {
    padding: 0;
  }
}

.flex-child.chakra-stack.footer {
  .chakra-button {
    margin-top: 1em;
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 2px solid #21bf96 !important;
}

.ant-menu-horizontal > .ant-menu-item-selected a,
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #21bf96 !important;
}

.ant-modal-content {
  overflow: auto;
  border-radius: 1rem;
}

.stack h1,
.stack p {
  text-align: center;
}

.two-h-stack {
  margin-top: 2em !important;
}

.two-h-stack .left button,
.two-h-stack .right button {
  margin: auto 0;
  vertical-align: middle;
  height: 85px;
  white-space: pre;
}
.left {
  border-right: 1px solid;
  padding: 0em 2em 0em 2em;
  margin: 0;
}
.right {
  padding: 0em 2em 0em 2em;
  margin-inline-start: 0 !important;
}

.centered {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
}

.nav_items {
  height: 72vh;
  overflow: scroll;
  width: 100%;
  position: relative;
  margin: 0px auto 0 auto;
  .nav_container {
    width: 100%;
    justify-content: center;
    margin: 1em auto 0 auto;
    align-items: center;
  }
}

@media (max-width: 768px) {
  body {
    letter-spacing: 0.04em !important;
    font-size: 15px;
  }
  .nav_items {
    margin: 0 auto;
    width: 100%;
    height: 80vh;
    overflow: scroll;
    .nav_container {
      width: 85%;
      margin: 0.5em auto 0 auto;
      justify-content: center;
      align-items: center;
    }
  }
  .chakra-container.flex-container {
    display: block;
  }
  .flex-child {
    flex: 1;
    margin: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .flex-child:first-child {
    border-right: none;
    margin: 0px 0px 0px 0px;
    padding: 0px 0px 0px 0px;
  }

  .contact .flex-child {
    &:nth-child(2) {
      margin: 0 0px 0px 0px;
      .chakra-button {
        margin-top: 1em;
        padding: 0;
      }
    }
  }

  .flex-child {
    &:nth-child(2) {
      margin: 0 0px 0px 0px;
      .chakra-button {
        margin-top: 0.2em;
        padding: 0;
      }
    }
  }
  .flex-child.chakra-stack.css-17ldpf5 {
    margin: 0.5em 0px 0px 0px;
    padding-top: 1.5em;
    .chakra-button {
      padding: 0;
    }
  }
  .two-h-stack .left button,
  .two-h-stack .right button {
    margin: auto 0;
    vertical-align: middle;
    height: 85px;
    white-space: pre;
    font-size: 15px;
  }
  .left {
    border-right: 1px solid;
    padding: 0em 1em 0em 1em;
    margin: 0;
  }
  .right {
    padding: 0em 1em 0em 1em;
    margin-inline-start: 0 !important;
  }
  .pinned .chakra-button.active .chakra-button__icon {
    top: -2px;
    left: 0px;
  }
  .pinned .chakra-button .chakra-button__icon {
    top: -2px;
    left: 0px;
  }
}

.ext-icon {
  top: -2px;
}

@media (max-width: 542px) {
  .logo {
    margin: 7px auto 0px auto;
    width: 60px;
    height: 60px;
  }
  .nav_items {
    height: 72vh;
    top: 0;
    .nav_container {
      margin: 0em auto 0 auto;
    }
  }
  .pinned:nth-child(1) {
    top: 0;
    margin: 15px 0px 0px 0%;
    align-self: start;
  }
  .pinned:nth-child(2) {
    top: 0;
    right: 0;
    margin: 15px 0% 0px 0px;
  }
  .pinned:nth-child(3) {
    bottom: 0;
    left: 0;
    margin: 0px 0px 15px 0%;
  }
  .pinned:nth-child(4) {
    bottom: 0;
    right: 0;
    margin: 0px 0% 15px 0px;
  }
  .copyright-wrapper {
    bottom: 0px;
    .copyright {
      width: 100%;
      font-size: 2vw;
    }
  }
}
@media (max-width: 320px) {
  .info {
    top: 55%;
  }
}
@media (max-width: 281px) {
  .pinned:nth-child(1) {
    top: 0;
    margin: 25px 0px 0px 2%;
    align-self: start;
  }
  .pinned:nth-child(2) {
    top: 0;
    right: 0;
    margin: 25px 2% 0px 0px;
  }
  .pinned:nth-child(3) {
    bottom: 0;
    left: 0;
    margin: 0px 0px 15px 2%;
  }
  .pinned:nth-child(4) {
    bottom: 0;
    right: 0;
    margin: 0px 2% 15px 0px;
  }
}
